import { Component } from 'vue-property-decorator'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import BadgeStats from '@/modules/level-two/views/event/modules/badges/dashboard/badge-stats.vue'
import Leaderboard from '@/modules/level-two/views/event/modules/badges/dashboard/leaderboard.vue'
import RegistrationTypes from '@/modules/level-two/views/event/modules/badges/dashboard/registration-types.vue'
import UniqueBadgesPrinted from '@/modules/level-two/views/event/modules/badges/dashboard/unique-badges-printed.vue'
import { mapState } from 'vuex'

@Component({
  name: 'GtrBadgesDashboardView',
  computed: {
    ...mapState('event', ['event'])
  },
  components: {
    BadgeStats,
    Leaderboard,
    RegistrationTypes,
    UniqueBadgesPrinted
  }
})
export default class GtrBadgesDashboardView extends GtrSuper {
  data () {
    return {
      lastSynced: '2023-11-03 00:00:00',
      activationCodes: 'Activation Codes'
    }
  }

  async created () {
    const badgeStats = await this.$store.dispatch('badges/getBadgeStats', { event_uuid: this.$route.params.event_uuid })
    const { dates = [] } = badgeStats
    await this.$store.dispatch('badges/getBadgesPrintByHour', {
      event_uuid: this.$route.params.event_uuid,
      dates
    })
  }

  get eventBanner () {
    return (this as any).event?.banner
  }
}
