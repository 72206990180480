import { render, staticRenderFns } from "./registration-types.vue?vue&type=template&id=df2de0e8&scoped=true&"
import script from "./registration-types.ts?vue&type=script&lang=ts&"
export * from "./registration-types.ts?vue&type=script&lang=ts&"
import style0 from "./registration-types.vue?vue&type=style&index=0&id=df2de0e8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "df2de0e8",
  null
  
)

export default component.exports