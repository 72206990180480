import { Component, Watch } from 'vue-property-decorator'
import moment from 'moment'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import Chart from 'chart.js'

@Component({
  name: 'GtrUniqueBadgesPrinted'
})
export default class GtrUniqueBadgesPrinted extends GtrSuper {
  data () {
    return {
      chart: null,
      dateItems: [],
      selectedDate: '',
      chartDatasets: [],
      hotHourRange: '',
      hotHourCount: 0
    }
  }

  findKeyByTotal (data, totalValue) {
    for (const [key, value] of Object.entries(data) as any) {
      if (value?._TOTAL === totalValue) {
        return key
      }
    }
    return ''
  }

  @Watch('$store.state.badges.badgeStats', { deep: true })
  onBadgeStatsChanged (newValue) {
    if (Object.keys(newValue).length > 0) {
      const { dates = [] } = newValue
      this.$data.dateItems = [...dates]
      this.$data.selectedDate = this.$data.dateItems[0] || ''
    }
  }

  get selectedDateandBadgeStatsByHourStatsLoaded () {
    return this.$data.selectedDate && Object.keys(this.$store.state.badges.badgeStatsByHour).length > 0
  }

  @Watch('selectedDateandBadgeStatsByHourStatsLoaded')
  onSelectedDateandBadgeStatsByHourStatsLoadedChange (newValue) {
    if (newValue) {
      this.onSelectedDateChange(this.$data.selectedDate)
    }
  }

  onSelectedDateChange (newValue) {
    const hash = {}
    const datasets: any = []
    const hourPrefixesFromBE = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23']

    const backgroundColors = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0']
    if (newValue && Object.keys(this.$store.state.badges.badgeStatsByHour[newValue]).length > 0) {
      const { registration_types = [] } = this.$store.state.badges.badgeStats
      const { badges_printed = [], hot_hour = {} } = this.$store.state.badges.badgeStatsByHour[this.$data.selectedDate]
      this.$data.hotHourCount = hot_hour?._TOTAL || 0
      const hotHour = parseInt(this.findKeyByTotal(badges_printed, this.$data.hotHourCount), 10)
      this.$data.hotHourRange = `${moment(hotHour, 'HH').format('ha')} - ${moment(hotHour + 1, 'HH').format('ha')}`
      const hash = { Default: Array.from({ length: 24 }, () => 0) }
      for (const type of registration_types) {
        hash[type] = Array.from({ length: 24 }, () => 0)
      }
      for (const [index, hour] of hourPrefixesFromBE.entries()) {
        const hourData = badges_printed[hour]
        const total = hourData._TOTAL
        if (total === 0) continue
        for (const registrationType in hourData) {
          if (registrationType === '_TOTAL') continue
          else if (registrationType === '_default') {
            hash.Default[index] = hourData[registrationType]
          } else {
            hash[registrationType][index] = hourData[registrationType]
          }
        }
      }

      Object.entries(hash).forEach(([key, value]) => {
        datasets.push({
          label: key,
          data: value,
          backgroundColor: key === 'Default' ? '#E5EDF6' : backgroundColors[datasets.length % backgroundColors.length],
          stack: 'Stack 0'
        })
      })
      const ctx: any = document.getElementById('unique-badges-printed-chart')
      this.createChart(ctx, hourPrefixesFromBE, datasets)
    }
  }

  createChart (ctx: any, labels: any[], datasets: any[]) {
    if (this.$data.chart) {
      this.$data.chart.destroy()
    }
    this.$data.chart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels,
        datasets
      },
      options: {
        responsive: true,
        aspectRatio: 3,
        plugins: {
          legend: {
            position: 'bottom'
          }
        },
        scales: {
          xAxes: [{
            stacked: true
          }],
          yAxes: [{
            stacked: true
          }]
        }
      }
    })
  }
}
