import { Component, Prop } from 'vue-property-decorator'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'

@Component({
  name: 'GtrRegistrationTypes'
})
export default class GtrRegistrationTypes extends GtrSuper {
  get registrationTypeData () {
    const { badges_printed_by_registration_type = [] } = this.$store.state.badges.badgeStats
    return badges_printed_by_registration_type
  }

  get numberOfBadgesPrintedByDefaultRegistrationType () {
    const { badges_printed: badgesPrintedTotal } = this.$store.state.badges.badgeStats
    const badgesPrintedByRegistrationType = this.registrationTypeData.reduce((acc, cur) => acc + cur.distinct_count, 0)
    return badgesPrintedTotal - badgesPrintedByRegistrationType
  }

  get numberOfBadgesUnprintedByDefaultRegistrationType () {
    const { unprinted_records: badgesUnprintedTotal } = this.$store.state.badges.badgeStats
    const badgesUnprintedByRegistrationType = this.registrationTypeData.reduce((acc, cur) => acc + cur.unprinted_count, 0)
    return badgesUnprintedTotal - badgesUnprintedByRegistrationType
  }
}
