import { Component, Watch } from 'vue-property-decorator'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import Chart from 'chart.js'

@Component({
  name: 'GtrBadgesBadgeStats'
})
export default class GtrBadgesBadgeStats extends GtrSuper {
  get totalBadges () {
    const { badges_printed = 0 } = this.$store.state.badges.badgeStats
    return badges_printed
  }

  get unprintedRecords () {
    const { unprinted_records = 0 } = this.$store.state.badges.badgeStats
    return unprinted_records
  }

  @Watch('$store.state.badges.badgeStats', { deep: true })
  onBadgeStatsChanged (newValue) {
    const labels: string[] = []
    const data: any[] = []
    const backgroundColor: string[] = []
    const {
      badges_printed_by_registration_type = [],
      badges_printed: badgesPrintedTotal
    } = this.$store.state.badges.badgeStats
    let counter = 0
    for (const regTypeData of badges_printed_by_registration_type) {
      const { name = '', total = 0 } = regTypeData
      labels.push(name)
      data.push(total)
      backgroundColor.push(this.$data.backgroundColor[counter])
      counter++
    }
    const ctx = document.getElementById('badge-stats-chart') as any
    if (this.$data.chart) {
      this.$data.chart.destroy()
    }
    this.$data.chart = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels,
        datasets: [
          {
            data,
            backgroundColor
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'right'
          },
          tooltip: {
            enabled: true
          }
        }
      },
      plugins: [
        {
          // id: 'custom_text',
          afterDraw: chart => {
            const ctx = chart!.canvas!.getContext('2d')
            if (ctx) {
              ctx.save()
              const centerX =
                (chart.chartArea.left + chart.chartArea.right) / 2
              const centerY =
                (chart.chartArea.top + chart.chartArea.bottom) / 2
              const fontSize = 32
              ctx.font = `bold ${fontSize}px sans-serif`
              ctx.textAlign = 'center'
              ctx.textBaseline = 'middle'
              ctx.fillStyle = '#565656'
              ctx.fillText(this.totalBadges.toString(), centerX, centerY)
              ctx.restore()
            }
          }
        }
      ]
    })
  }

  data () {
    return {
      chart: null,
      backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0']
    }
  }
}
